<template>
  <v-sheet color="primary" class="home" style="height: 100vh">
    <div class="login d-flex align-center justify-center" style="height: 100%">
      <v-card max-width="600" class="pa-5 mx-3">
        <!-- Card Header -->
        <div class="d-flex align-center flex-column">
          <router-link to="/">
            <v-img
              src="~@/assets/logo.svg"
              min-height="75"
              max-width="200"
              contain
            ></v-img>
          </router-link>
          <v-card-title
            style="word-break: normal; text-align: center"
            class="headline"
            >Login</v-card-title
          >
        </div>
        <v-subheader class="error--text caption">{{ errorMsg }}</v-subheader>
        <!-- End Card Header -->
        <!-- Login Fields -->
        <div class="mt-2">
          <v-row class="mb-5">
            <v-col cols="12">
              <v-text-field
                label="Email"
                v-model="credentials.email"
                type="text"
                class="px-4"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Password"
                v-model="credentials.password"
                type="password"
                class="px-4"
                outlined
                @keydown.enter="login"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="px-7">
              <v-btn
                color="primary"
                large
                elevation="0"
                block
                :loading="loader"
                @click.stop="login"
                >Login</v-btn
              >
            </v-col>
            <v-col cols="12" class="d-flex align-center px-7 py-0">
              <v-divider></v-divider>
              <v-subheader>OR</v-subheader>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="d-flex justify-center px-7 py-0">
              <span class="register-link" @click="$router.push('/register')">Register</span>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </v-sheet>
</template>

<script>

import * as fb from '../firebase'

export default {
  name: "Login",

  data: () => ({
    credentials: {
      email: "testing123@gmail.com",
      password: "1234567890",
    },
    errorMsg: '',
    loader: false,
  }),

  methods: {
    async login() {
      try {
        
        this.loader = true

        const loginResult = await this.$store.dispatch('loginWithFirebase', this.credentials)

        this.$router.push('/home')

        this.loader = false

      } catch (error) {
        console.log(error)
        this.errorMsg = 'Incorrect Email or Password'
        this.loader = false
      }
    }
  }
}
</script>
<style>

.register-link {
  cursor: pointer;
  color: #000000;
  font-weight: bold;
  text-decoration: underline;
}

</style>
